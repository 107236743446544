'use client';

import { useEffect, useState } from 'react';

import { NnError, nnErrorSchema } from '@/hooks/fetch/use-auth-fetch-ky';
import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import BootstrapError from './bootstrap-error';
import { TenantNotFound } from './tenant-not-found';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const [formattedError] = useState<NnError | null>(() => {
    const parseResult = nnErrorSchema.safeParse(error);
    if (parseResult.success) {
      return parseResult.data;
    }
    return null;
  });

  useEffect(() => {
    if (formattedError) {
      const firstError = formattedError.errors.at(0);
      if (formattedError.errors.length === 1 && firstError?.code === 'NN1002') {
        // Don't capture the Tenant Not Found error
        return;
      }
      Sentry.captureException(formattedError);
      return;
    }
    // capture the original error if it's not an NN error
    Sentry.captureException(error);
  }, [formattedError, error]);

  // Check if there is an error code. This means it's an NN error.
  // If the error is NN1002, then it's a "Tenant Not Found" error
  if (formattedError?.errors[0]?.code === 'NN1002') {
    return <TenantNotFound />;
  }

  return <BootstrapError title={'Failed to load.'} />;
}
