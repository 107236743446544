import { FileCircleExclamationIcon } from '@/components/icons/file-circle-exclamation-icon';

export function TenantNotFound() {
  return (
    <html lang="en">
      <body className="text-body h-screen w-screen">
        <div className="flex h-full w-full flex-col items-start justify-center p-4 lg:items-center">
          <div>
            <div className="mb-6 flex flex-col items-start gap-5 lg:flex-row lg:items-end">
              <FileCircleExclamationIcon className="text-error mr-auto inline-block h-8 lg:mr-0" />
              <h1 className="text-heading-2 font-bold text-black">
                {'Page not found'}
              </h1>
            </div>
            <p className="mb-8 max-w-[600px]">
              {"We're sorry, but the page you're looking for cannot be found."}
            </p>

            <p className="max-w-[600px]">
              {
                'If you continue to experience issues, please contact our support team for assistance.'
              }
            </p>
          </div>
        </div>
      </body>
    </html>
  );
}
